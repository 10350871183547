














import { defineComponent } from "@vue/composition-api"
import IconButton from "@/components/buttons/IconButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { alertContainer } from "@/containers/AlertContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import Copy from "@/utilities/Copy"
import { Meeting } from "@/models/Meeting"

export default defineComponent({
  props: {
    meeting: {
      type: Meeting,
      required: true,
    },
  },
  components: {
    IconButton,
    I18nFormattedMessage,
  },
  setup(props) {
    const { formatMessage } = i18nContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()

    const onLinkClick = async () => {
      await Copy(props.meeting.users.map(u => u.email).join(";"))
      showSuccessMessage("コピーしました")
    }

    return {
      onLinkClick,
      i18nFormattedMessage: formatMessage,
    }
  },
})
