









import { defineComponent, computed, PropType } from "@vue/composition-api"

/**
 * 参考
 *   https://github.com/eliep/vue-avatar/blob/master/src/Avatar.vue
 */
export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 32,
    },
    image: {
      type: Object as PropType<{ contentType: string; data: string }>,
    },
  },

  setup(props) {
    const letter = computed<string>(() => props.text[0])
    const backgroundColor = computed<string>(() =>
      getBackgroundColor(props.text)
    )
    const textColor = computed<string>(() =>
      getTextColor(backgroundColor.value)
    )
    const imageSrc = computed<string | null>(() => {
      if (!props.image) return null
      return getimageSrc(props.image)
    })
    return { letter, backgroundColor, textColor, imageSrc }
  },
})

const backgroundColors = [
  "#F44336",
  "#FF4081",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
  "#607D8B",
]

function getBackgroundColor(text: string) {
  return backgroundColors[text.length % backgroundColors.length]
}

function getTextColor(hexColor: string) {
  return lightenDarkenColor(hexColor)
}

/**
 * 参考
 *   https://css-tricks.com/snippets/javascript/lighten-darken-color/
 */
function lightenDarkenColor(hexColor: string, amount = 80) {
  const hexColorValue = hexColor.replace("#", "")
  const hexColorValueAsInt = parseInt(hexColorValue, 16)

  let r = (hexColorValueAsInt >> 16) + amount
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((hexColorValueAsInt >> 8) & 0x00ff) + amount
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (hexColorValueAsInt & 0x0000ff) + amount
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`
}

function getimageSrc(image: { contentType: string; data: string }): string {
  return `data:${image.contentType};base64,${image.data}`
}
