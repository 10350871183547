

























import { defineComponent } from "@vue/composition-api"
import RegularMeetingSolutionError from "@/models/RegularMeetingSolutionError"

export default defineComponent({
  setup(_props, context) {
    const { title, subtitle } = context.slots

    if (!title) throw new RegularMeetingSolutionError({ message: "no-header" })

    return { hasSubtitle: !!subtitle }
  },
})
