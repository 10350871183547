



























































































































































































import { computed, defineComponent, reactive } from "@vue/composition-api"
import AvatarList from "@/components/AvatarList.vue"
import I18nFormattedDateTime from "@/components/i18n/I18nFormattedDateTime.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Icon from "@/components/Icon.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import PageHeader from "@/components/PageHeader.vue"
import EditMeetingModal from "./EditMeetingModal.vue"
import ApproveModal from "./ApproveModal/ApproveModal.vue"
import ConfirmDialogWithCheckbox from "@/components/dialogs/ConfirmDialogWithCheckbox.vue"
import User from "@/models/User"
import { i18nContainer } from "@/containers/I18nContainer"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { goToRoute, goBack, getHistory } from "@/router"
import RouteName, { routeNameToString } from "@/constants/RouteName"
import { WorkflowIn } from "@/models/Workflow"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { app as microsoftTeams } from "@microsoft/teams-js"
import { projectsContainer } from "@/containers/ProjectsContainer"
import TeamsContext from "@/models/TeamsContext"

interface State {
  location: string
  isWorkflowIsSended: boolean
  users: Array<User>
  isLoading: boolean
  isOpenDialog: boolean
  isOpenConfirmDialog: boolean
  approveModalOpen: boolean
}

export default defineComponent({
  props: {
    parent: {
      type: MeetingStructure,
      required: true,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    processingWorkflow: {
      type: WorkflowIn,
      required: false,
    },
    disableRevertMemo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AvatarList,
    EditMeetingModal,
    I18nFormattedDateTime,
    I18nFormattedMessage,
    ConfirmDialogWithCheckbox,
    Icon,
    IconButton,
    PageHeader,
    ApproveModal,
  },
  setup(props) {
    const { formatDateTime, formatMessage } = i18nContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const { deleteMeetingAsync } = meetingContainer.useContainer()
    const { getWorkflowsAsync } = workflowContainer.useContainer()
    const state = reactive<State>({
      location: "",
      users: [],
      isLoading: false,
      isOpenDialog: false,
      isOpenConfirmDialog: false,
      approveModalOpen: false,
      isWorkflowIsSended: false,
    })

    const startTimeString = computed<string>(() =>
      formatDateTime(props.meeting.startTime.toJsDate(), {
        hour: "numeric",
        minute: "numeric",
      })
    )

    const endTimeString = computed<string>(() =>
      formatDateTime(props.meeting.endTime.toJsDate(), {
        hour: "numeric",
        minute: "numeric",
      })
    )

    const parentMeetingSubject = computed(() => props.parent.name)
    const isInTeams = computed(() => window.inTheTeamsIFrame)

    const onOpenInBrowserButtonClick = async () => {
      const context = await getContext()
      const plainTeamsContext = {
        appId: context.appId,
        rootFolder: context.rootFolder,
        teamId: context.teamId,
        tid: context.tid,
        subEntityId: context.subEntityId,
        entityId: context.entityId,
        groupId: context.groupId,
        userObjectId: "",
        channelId: context.channelId,
        isPrivate: context.isPrivate,
        teamSiteDomain: context.teamSiteDomain,
        teamSitePath: context.teamSitePath,
      } as TeamsContext
      const contextStr = window.btoa(
        encodeURIComponent(JSON.stringify(plainTeamsContext))
      )
      window.open(
        `${location.href}?tid=${context.tid}&ctx=${contextStr}&mode=browser`
      )
    }

    const onOpenTeamsMeetingButtonClick = async () => {
      const url = props.meeting.onlineMeetingUrl
      if (isInTeams.value) {
        microsoftTeams.openLink(url)
      } else {
        window.open(url, "_blank")
      }
    }

    const onOpenApproveButtonClick = async () => {
      if (props.processingWorkflow) return
      state.approveModalOpen = true
    }

    const isEnded = computed(() => {
      return props.meeting.endTime.isBefore(new Date(), "minutes")
    })

    const deleteMeeting = async () => {
      state.isLoading = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await deleteMeetingAsync(
        project.siteId,
        context.entityId,
        props.parent.id,
        props.meeting
      )
      goToRoute({
        name: RouteName.MeetingSeries,
        params: {
          id: props.parent.id,
        },
      })
      state.isLoading = false
      state.isOpenConfirmDialog = false
    }

    const goBackPage = () => {
      const histories = getHistory()
      histories.length > 1 &&
      histories[histories.length - 1] ===
        routeNameToString(RouteName.PastMemoList).toLowerCase()
        ? goBack()
        : goToRoute({
            name: RouteName.MeetingSeries,
            params: {
              id: props.parent.id || "",
            },
          })
    }

    const updateWorkflow = async () => {
      await getWorkflowsAsync(false, props.parent.id, true)
    }

    return {
      state,
      isEnded,
      isInTeams,
      parentMeetingSubject,
      i18nFormattedMessage: formatMessage,
      startTimeString,
      endTimeString,
      onOpenInBrowserButtonClick,
      onOpenApproveButtonClick,
      onOpenTeamsMeetingButtonClick,
      deleteMeeting,
      goBackPage,
      updateWorkflow,
    }
  },
})
