






















import { computed, defineComponent, PropType } from "@vue/composition-api"
import Avatar from "./Avatar.vue"

/**
 * 参考
 *   https://github.com/eliep/vue-avatar/blob/master/src/Avatar.vue
 */
export default defineComponent({
  props: {
    items: {
      type: Array as PropType<
        Array<{
          text: string
          image?: { contentType: string; data: string }
        }>
      >,
      required: true,
    },
    itemSize: {
      type: Number,
      default: 32,
    },
    limitVisible: {
      type: Number,
      default: 0,
      validator: (value: number) => {
        if (!Number.isSafeInteger(value)) return false
        if (value < 0) return false
        return true
      },
    },
  },
  components: {
    Avatar,
  },
  setup(props) {
    const visibleItems = computed(() =>
      props.limitVisible
        ? props.items.slice(0, props.limitVisible)
        : props.items
    )
    const hiddenItems = computed(() =>
      props.limitVisible ? props.items.slice(props.limitVisible) : []
    )
    const listMinWidth = computed(
      () =>
        visibleItems.value.length * props.itemSize -
        (visibleItems.value.length - 1) * (props.itemSize / 2)
    )

    return { visibleItems, hiddenItems, listMinWidth }
  },
})
