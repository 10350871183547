





































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import ResizelableArea, { ElementWidth } from "@/layouts/ResizelableArea.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Loading from "@/components/Loading.vue"
import { TypedComponentProps } from "@/vue-props-types"
import { Props } from "./types"
import { Meeting, MeetingStructure } from "@/models/Meeting"
import ContentHeader from "./ContentHeader.vue"
import DetailsDrawer from "./DetailsDrawer"
import PageHeader from "./PageHeader.vue"
import RouteName from "@/constants/RouteName"
import { goToRoute } from "@/router"
import Memo from "@/views/InMeeting/Memo/Memo.vue"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import { metricContainer } from "@/containers/MetricContainer"
import { WorkflowIn } from "@/models/Workflow"

interface State {
  meeting: Meeting | null
  processingWorkflow: WorkflowIn | null
  parentMeeting: MeetingStructure | null
  editMode: boolean
  elementWidth: ElementWidth | null
  isDetailsDrawerOpen: boolean
  isRevertMemoModalOpen: boolean
  isEditorInitialized: boolean
}

const propsOptions = {
  parentId: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: "",
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      ContentHeader,
      DetailsDrawer,
      I18nFormattedMessage,
      Loading,
      PageHeader,
      Memo,
      ResizelableArea,
    },
    setup(props) {
      const defaultRightOpen = 356 // (px)
      const minRightClose = 40 // (px)

      const { formatMessage } = i18nContainer.useContainer()
      const state = reactive<State>({
        meeting: null,
        parentMeeting: null,
        editMode: true,
        processingWorkflow: null,
        elementWidth: {
          left: null,
          right: defaultRightOpen,
        },
        isDetailsDrawerOpen: true,
        isRevertMemoModalOpen: false,
        isEditorInitialized: false,
      })

      const { CreateGoalMetric } = metricContainer.useContainer()
      const {
        state: workflowState,
        getWorkflowsAsync,
      } = workflowContainer.useContainer()
      const {
        state: meetingState,
        loadMeetingDetailAsync,
        syncMeetingAsync,
      } = meetingContainer.useContainer()
      const { getContext } = teamsContextContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const { showSuccessMessage } = alertContainer.useContainer()

      const goToParentMeeting = () => {
        goToRoute({
          name: RouteName.MeetingSeries,
          params: { id: props.parentId },
        })
      }

      watch(
        () => state.editMode,
        newValue => {
          state.elementWidth = Object.assign({}, state.elementWidth, {
            left: null,
            right: newValue ? defaultRightOpen : minRightClose,
          })
        }
      )

      const changeRightArea = (newValue: boolean) => {
        if (newValue && (state.elementWidth?.right ?? 0) < defaultRightOpen) {
          state.elementWidth = Object.assign({}, state.elementWidth, {
            left: null,
            right: defaultRightOpen,
          })
        } else if (
          !newValue &&
          (state.elementWidth?.right ?? 0) > minRightClose
        ) {
          state.elementWidth = Object.assign({}, state.elementWidth, {
            left: null,
            right: minRightClose,
          })
        }
      }

      watch(
        () => workflowState.workflows.value,
        newValue => {
          state.processingWorkflow =
            newValue?.find(
              w =>
                w.meetingId === props.id && (w.status === 0 || w.status === 1)
            ) || null
        }
      )

      watch(
        () => meetingState.meetings.value,
        async newMeetings => {
          const context = await getContext()
          const project = await getProjectAsync(context.entityId)
          const parentMeeting = (newMeetings ?? []).find(
            i => i.id === props.parentId
          )
          state.parentMeeting = parentMeeting
            ? new MeetingStructure(parentMeeting)
            : null
          if (!state.parentMeeting) return
          state.meeting =
            state.parentMeeting.meetings.find(i => i.id === props.id) || null
          state.editMode =
            state.meeting?.endTime.isAfter(new Date(), "minutes") || false
          if (state.meeting && project.siteId) {
            await loadMeetingDetailAsync(
              project.siteId,
              context.entityId,
              state.meeting
            )
          }
        }
      )

      const setMeetings = async () => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        const workflows = await getWorkflowsAsync(false, props.parentId)
        const meetings = meetingState.meetings.value ?? []
        const parentMeeting = meetings.find(i => i.id === props.parentId)
        state.processingWorkflow =
          workflows.find(
            w => w.meetingId === props.id && (w.status === 0 || w.status === 1)
          ) || null
        state.parentMeeting = parentMeeting
          ? new MeetingStructure(parentMeeting)
          : null
        if (!state.parentMeeting) return
        state.meeting =
          state.parentMeeting.meetings.find(i => i.id === props.id) || null
        state.editMode =
          state.meeting?.endTime.isAfter(new Date(), "minutes") || false

        if (state.meeting && project.siteId) {
          await loadMeetingDetailAsync(
            project.siteId,
            context.entityId,
            state.meeting
          )
          await syncMeetingAsync(
            project.siteId,
            context.entityId,
            state.meeting,
            state.parentMeeting.id,
            (updateMeeting, isUpdate) => {
              state.meeting = updateMeeting
              if (isUpdate) showSuccessMessage("予定表のデータと同期しました")
            }
          )
        }
      }

      watch(
        () => props.id,
        async newValue => {
          if (!newValue) goToParentMeeting()
          else {
            await setMeetings()
          }
        }
      )

      onMounted(async () => {
        const context = await getContext()
        CreateGoalMetric(context.tid, context.userObjectId)
        await setMeetings()
      })

      const onUpdate = (meeting: Meeting) => {
        state.meeting = meeting
      }

      return {
        state,
        meetingState,
        changeRightArea,
        onUpdate,
        i18nFormattedMessage: formatMessage,
      }
    },
  }
)
