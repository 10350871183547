











































import { defineComponent, reactive } from "@vue/composition-api"
import DefaultButton from "../buttons/DefaultButton.vue"
import IconButton from "../buttons/IconButton.vue"

interface State {
  isChecked: boolean
}

export default defineComponent({
  components: { DefaultButton, IconButton },
  props: {
    customName: String,
    visible: Boolean,
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabledOkBtn: Boolean,
  },
  setup(_, { emit, slots }) {
    if (!slots["checkboxMsg"]) throw new Error("no-checkboxMsg-slot")

    const state = reactive<State>({
      isChecked: false,
    })

    const closeDialog = () => {
      state.isChecked = false
      emit("cancel")
    }
    const onOkClick = () => {
      state.isChecked = false
      emit("ok")
    }
    return { state, closeDialog, onOkClick }
  },
})
