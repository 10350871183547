





















































import {
  defineComponent,
  reactive,
  watch,
  computed,
  PropType,
} from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import ProjectFile from "@/models/ProjectFile"
import Filereference from "./Filereference.vue"
import FileUpload, { NewFileState } from "./FileUpload.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  isLoading: boolean
  selectedItems: Array<ProjectFile>
  uploadFiles: Array<{ state: NewFileState; file: ProjectFile }>
}

const propsOptions = {
  value: {
    type: Array as PropType<Array<ProjectFile>>,
  },
  open: {
    type: Boolean,
  },
  filePath: {
    type: String,
  },
  tab: {
    type: Number,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  currentFolder: {
    type: String,
  },
}

export default defineComponent({
  props: propsOptions,
  components: {
    DefaultDialog,
    I18nFormattedMessage,
    Filereference,
    FileUpload,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isLoading: false,
      selectedItems: [],
      uploadFiles: [],
    })
    const { getContext, deleteFiles } = teamsContextContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const currentTab = computed({
      get: () => props.tab ?? 0,
      set: (newValue: number) => {
        emit("click:tab", newValue)
      },
    })

    watch(
      () => props.open,
      val => {
        if (val) {
          state.selectedItems = props.value?.map(v => new ProjectFile(v)) ?? []
          state.uploadFiles = state.selectedItems
            .filter(s => s.parentPath === props.currentFolder)
            .map(s => ({
              state: "current",
              file: s,
            }))
        }
      }
    )

    watch(
      () => state.uploadFiles,
      newValues => {
        state.selectedItems = state.selectedItems.filter(
          f =>
            newValues.find(nf => nf.file.webUrl === f.webUrl)?.state !== "new"
        )
      }
    )

    const changeFolder = (jumpToPath: string) => {
      emit("changeFolder", jumpToPath ? jumpToPath : "/")
    }

    const onCancel = async () => {
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await deleteFiles(
        project.siteId,
        state.uploadFiles.filter(u => u.state === "new").map(f => f.file)
      )
      emit("cancel")
    }

    const onOk = async () => {
      try {
        state.isLoading = true
        const deleted = state.uploadFiles
          .filter(u => u.state === "delete")
          .map(f => f.file)
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        await deleteFiles(project.siteId, deleted)
        state.selectedItems = state.selectedItems
          .filter(u => !deleted.some(df => df.webUrl === u.webUrl))
          .concat(
            state.uploadFiles.filter(u => u.state === "new").map(f => f.file)
          )
        emit("input", state.selectedItems)
      } finally {
        state.isLoading = false
      }
    }

    return {
      state,
      currentTab,
      changeFolder,
      onCancel,
      onOk,
    }
  },
})
