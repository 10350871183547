









































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Attachments from "./AttachmentFiles/Attachments.vue"
import Icon from "@/components/Icon.vue"
import LinkGet from "@/components/LinkGet.vue"
import AgendaPart from "@/components/AgendaPart.vue"
import MinutesDownload from "./MinutesDownload/MinutesDownload.vue"
import SendChannel from "./SendChannel.vue"
import MailaddressGet from "./MailaddressGet.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import { Agenda, Meeting, MeetingStructure } from "@/models/Meeting"
import DateTime from "@/models/DateTime"

export default defineComponent({
  props: {
    meeting: {
      type: Meeting,
      required: true,
    },
    parent: {
      type: MeetingStructure,
      required: true,
    },
  },
  components: {
    SendChannel,
    Attachments,
    MinutesDownload,
    I18nFormattedMessage,
    Icon,
    LinkGet,
    MailaddressGet,
    AgendaPart,
  },
  setup(_props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()

    const meetingValue = computed(() => _props.meeting)
    const parentId = computed(() => _props.parent.id)
    const meetingLinks = computed(() => {
      return _props.parent.links ?? []
    })

    const onUpdate = (meeting: Meeting) => {
      emit("update", meeting)
    }

    const agenda = ref(
      {} as Agenda & {
        percent: number
      }
    )
    const timer = ref("")
    const setTimer = () => {
      const now = new DateTime(new Date())
      if (now.isBefore(_props.meeting.startTime, "minutes")) {
        timer.value = "開始前"
        return
      }
      if (_props.meeting.endTime.isSameOrBefore(now, "minutes")) {
        timer.value = "終了"
        return
      }
      const allMin = _props.meeting.endTime.getDifference(now, "minutes")
      const hour = Math.floor(allMin / 60)
      const min = Math.ceil(allMin % 60)
      timer.value = `${hour ? `${hour}時間` : ""}${min ? `${min}分` : ""}`
      return
    }

    const setAgendas = () => {
      let elapsedTime = new DateTime(new Date()).getDifference(
        _props.meeting.startTime,
        "minutes"
      )
      const agendas =
        (_props.meeting.agendas || []).map(a => {
          const time = a.times || 0
          const ret = Object.assign({}, a, {
            percent: Math.max(
              0,
              Math.min(Math.min((elapsedTime / (time || 1)) * 100), 100)
            ),
          })
          elapsedTime -= time
          return ret
        }) || []
      const target = agendas.find(a => a.percent < 100)
      agenda.value = target || agendas[agendas.length - 1]
    }
    let intervalId = 0
    setAgendas()
    setTimer()
    onMounted(() => {
      intervalId = window.setInterval(() => {
        setAgendas()
        setTimer()
      }, 1000)
    })
    onUnmounted(() => {
      clearInterval(intervalId)
    })

    const existAgendaTime = () => {
      const times = _props.meeting?.agendas?.some(a => !a.times)
      return !times
    }

    return {
      parentId,
      meetingValue,
      meetingLinks,
      onUpdate,
      agenda,
      timer,
      i18nFormattedMessage: formatMessage,
      existAgendaTime,
    }
  },
})
