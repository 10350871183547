









































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import User from "@/models/User"
import { i18nContainer } from "@/containers/I18nContainer"
import TextField from "@/components/textFields/TextField.vue"
import { Meeting, MeetingContent, MeetingStructure } from "@/models/Meeting"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import ExternalAttendeesAdd from "@/components/ExternalAttendeesAdd.vue"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"
import AgendaInput from "@/views/NewMeetingCreation/AgendaInput.vue"

interface State {
  location: string
  userInTeams: Array<User>
  exUsers: Array<User>
  members: Array<User>
  isFormValid: boolean
  isLoadingUpdate: boolean
  meetingContent: MeetingContent
}

export default defineComponent({
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
    parent: {
      type: MeetingStructure,
      required: true,
    },
  },
  components: {
    TextField,
    DefaultDialog,
    ExternalAttendeesAdd,
    I18nFormattedMessage,
    UsersAutoComplete,
    AgendaInput,
  },
  setup(props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()
    const { getContext, getMembers } = teamsContextContainer.useContainer()
    const { updateMeetingAsync } = meetingContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const state = reactive<State>({
      location: "",
      userInTeams: [],
      exUsers: [],
      members: [],
      isFormValid: false,
      isLoadingUpdate: false,
      meetingContent: new MeetingContent(),
    })

    const onOpenEdit = (meeting: Meeting) => {
      state.location = meeting.location
      state.userInTeams = meeting.users.filter(u => !!u.id)
      state.exUsers = meeting.users.filter(u => !u.id)
      state.meetingContent = new MeetingContent({
        purpose: meeting.purpose,
        goal: meeting.goal,
        agendas: meeting.agendas?.filter(a => a.agenda),
      })
    }

    const attendeesCount = computed<number>(() => {
      const internalAttendees = state.userInTeams.filter(
        u => u.userId !== undefined
      )
      return internalAttendees.length
    })

    const setValid = (isValid: boolean) => {
      state.isFormValid = isValid
    }

    watch(
      () => props.meeting,
      newValue => {
        onOpenEdit(newValue)
      }
    )

    watch(
      () => props.isOpenDialog,
      newValue => {
        if (newValue) onOpenEdit(props.meeting)
      }
    )

    onMounted(async () => {
      state.members = (await getMembers()).map(m => new User(m))
      onOpenEdit(props.meeting)
    })

    const onCancel = () => {
      emit("close")
    }

    const onOk = async () => {
      state.isLoadingUpdate = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      await updateMeetingAsync(
        project.siteId,
        context.entityId,
        new Meeting(
          Object.assign(props.meeting, {
            location: state.location,
            users: state.userInTeams.concat(state.exUsers),
            purpose: state.meetingContent.purpose,
            goal: state.meetingContent.goal,
            agendas: state.meetingContent.agendas?.filter(a => a.agenda),
          })
        ),
        props.parent.id
      )
      state.isLoadingUpdate = false
      emit("close")
    }

    return {
      state,
      i18nFormattedMessage: formatMessage,
      attendeesCount,
      onCancel,
      onOk,
      setValid,
    }
  },
})
