









































































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api"
import DefaultDialog from "@/components/dialogs/DefaultDialog.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Loading from "@/components/Loading.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import MinutesEdit from "@/views/InMeeting/MinutesDownload/MinutesEdit.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import User from "@/models/User"
import { minutesContainer } from "@/containers/MinutesContainer"
import { Meeting } from "@/models/Meeting"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { workflowContainer } from "@/containers/WorkflowContainer"
import { WorkflowOut } from "@/models/Workflow"
import { alertContainer } from "@/containers/AlertContainer"
import ApiError from "@/models/Errors/ApiError"
import UsersAutoComplete from "@/views/UsersAutoComplete.vue"
import Minutes from "@/models/Minutes"

interface State {
  isLoading: boolean
  isFormValidInput: boolean
  isFormValid: boolean
  pdfPath: string
  members: Array<User>
  users: Array<User>
  comment: string
  isCreatingPdf: boolean
  blob: Blob | null
}

interface Props {
  parentId: string
  meeting: Meeting
  open: boolean
}

export default defineComponent<Props>({
  props: {
    parentId: {
      type: String,
      required: true,
    },
    meeting: {
      type: Object as PropType<Meeting>,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Loading,
    TextArea,
    DefaultDialog,
    I18nFormattedMessage,
    UsersAutoComplete,
    MinutesEdit,
    DefaultButton,
  },
  setup(props, { emit }) {
    const state = reactive<State>({
      isLoading: false,
      isFormValidInput: true,
      isFormValid: false,
      users: [],
      members: [],
      comment: "",
      pdfPath: "",
      isCreatingPdf: true,
      blob: null,
    })
    const minutesData = ref<Minutes>(
      new Minutes().setFromMeeting(props.meeting)
    )
    watch(
      () => props.meeting,
      newValue => {
        minutesData.value.setFromMeeting(newValue)
      },
      { deep: true }
    )
    const step = ref(1)
    const { state: meetingState } = meetingContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()
    const {
      getMembers,
      getContext,
      getTeams,
    } = teamsContextContainer.useContainer()
    const {
      uploadMinutesPdfAsync,
      getMinutesAsync,
    } = minutesContainer.useContainer()
    const {
      showSuccessMessage,
      showWarningMessage,
    } = alertContainer.useContainer()
    const {
      getWorkflowsAsync,
      creaeWorkflows,
    } = workflowContainer.useContainer()

    const approverCount = computed<number>(() => {
      const approvres = state.users.filter(u => u.userId !== undefined)
      return approvres.length
    })

    const init = async () => {
      state.isCreatingPdf = true
      state.pdfPath = ""
      const workflows = await getWorkflowsAsync(false, props.parentId)
      const workflow = workflows.sort((a, b) =>
        b.createdAt.isAfter(a.createdAt, "seconds") ? 1 : -1
      )
      state.members = (await getMembers()).map(m => new User(m))
      state.users = workflow.length
        ? state.members.filter(a =>
            workflow[0].approvers.some(ap => ap.userId === a.userId)
          )
        : []
      state.blob = await getMinutesAsync(
        minutesData.value,
        meetingState.memo.value ?? ""
      )
      state.pdfPath = window.URL.createObjectURL(state.blob)
      state.isCreatingPdf = false
    }

    const next = async () => {
      step.value = 2
      await init()
    }

    const openMemo = () => {
      window.open(state.pdfPath)
    }

    watch(
      () => props.open,
      async newValue => {
        if (newValue) {
          step.value = 1
        }
      }
    )

    const onCancel = () => {
      if (state.pdfPath) window.URL.revokeObjectURL(state.pdfPath)
      emit("close")
    }

    const onOk = async () => {
      if (!state.blob) return
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      state.isLoading = true
      const pdf = await uploadMinutesPdfAsync(
        project.siteId,
        props.meeting,
        state.blob,
        "pdf"
      )
      const otherTeams = await getTeams([context.groupId])
      const team = otherTeams[0]?.team.displayName || ""
      const channel =
        otherTeams[0]?.channels.find(c => context.channelId === c.id)
          ?.displayName || ""
      try {
        await creaeWorkflows(
          new WorkflowOut({
            appId: context.appId,
            applicantComment: state.comment,
            filePath: pdf?.webUrl || "",
            groupId: context.groupId,
            siteId: project.siteId,
            meetingId: props.meeting.id,
            meetingStructureId: props.parentId,
            teamId: context.teamId,
            channelId: context.channelId,
            approvers: state.users.map(u => u.userId || ""),
            teamName: team,
            channelName: channel,
            isFromPrivate: context.isPrivate,
          })
        )
        showSuccessMessage("議事内容確認依頼を送付しました")
      } catch (e) {
        if (
          e instanceof ApiError &&
          e.status === 400 &&
          e.errorInfo?.code === "E00002"
        ) {
          showWarningMessage("既に依頼済みです")
          emit("update")
        } else {
          throw e
        }
      } finally {
        if (state.pdfPath) window.URL.revokeObjectURL(state.pdfPath)
        state.isLoading = false
      }
      emit("close")
    }

    return {
      state,
      minutesData,
      approverCount,
      openMemo,
      next,
      onCancel,
      onOk,
      step,
    }
  },
})
