

















import { defineComponent } from "@vue/composition-api"
import IconButton from "@/components/buttons/IconButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { i18nContainer } from "@/containers/I18nContainer"
import Copy from "@/utilities/Copy"

export default defineComponent({
  props: {
    customName: {
      type: String,
      default: "",
    },
    iconSize: {
      type: [String, Number],
      default: 24,
    },
    smallBtn: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 2,
    },
  },
  components: {
    IconButton,
    I18nFormattedMessage,
  },
  setup() {
    const { formatMessage } = i18nContainer.useContainer()
    const { getDeepLinkUrl } = teamsContextContainer.useContainer()
    const { showSuccessMessage } = alertContainer.useContainer()
    const onLinkClick = async () => {
      const url = await getDeepLinkUrl(location.pathname)
      await Copy(url)
      showSuccessMessage("コピーしました")
    }

    return {
      onLinkClick,
      i18nFormattedMessage: formatMessage,
    }
  },
})
