

















import { defineComponent, ref, PropType, computed } from "@vue/composition-api"

export interface ElementWidth {
  left: number | null
  right: number | null
}

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<ElementWidth>,
    },
    leftMinWidth: {
      type: Number,
      default: 10,
    },
    rightMinWidth: {
      type: Number,
      default: 10,
    },
    hideFooter: Boolean,
  },
  setup(props, { emit }) {
    const container = ref<HTMLDivElement>()
    const resizer = ref<HTMLDivElement>()
    const right = ref<HTMLDivElement>()
    const left = ref<HTMLDivElement>()

    const leftStyle = computed(
      () => "width:" + (props.value?.left ? `${props.value.left}px` : "auto")
    )
    const rightStyle = computed(
      () => "width:" + (props.value?.right ? `${props.value.right}px` : "auto")
    )
    const changeWidth = (x: number) => {
      const containerElement = container.value
      const leftElement = left.value
      const resizerElement = resizer.value
      const rightElement = right.value
      if (!containerElement || !leftElement || !resizerElement || !rightElement)
        return

      const rect = leftElement.getBoundingClientRect()
      const adjust: number = rect.left + resizerElement.offsetWidth
      const leftElementWidth: number = Math.max(x - adjust, props.leftMinWidth)
      leftElement.style.width = leftElementWidth + "px"
      const rightElementWidth = Math.max(
        containerElement.offsetWidth -
          leftElementWidth -
          resizerElement.offsetWidth -
          1,
        props.rightMinWidth
      )
      rightElement.style.width = rightElementWidth + "px"
      return {
        left: leftElementWidth,
        right: rightElementWidth,
      } as ElementWidth
    }
    const resizeEnd = (e: DragEvent) => {
      const ret = changeWidth(e.pageX)
      emit("input", ret)
    }
    return {
      container,
      right,
      left,
      resizer,
      resizeEnd,
      leftStyle,
      rightStyle,
    }
  },
})
