















































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api"
import Icon from "@/components/Icon.vue"
import IconButton from "@/components/buttons/IconButton.vue"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import FileIcons from "./FileIcons.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import ProjectFile from "@/models/ProjectFile"
import FileExplore from "./FileExplore.vue"
import { Meeting } from "@/models/Meeting"
import { getCleanPath } from "@/api/FileApi"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  files: Array<ProjectFile>
  fileExplorePath: string | null
  isLoadingCreate: boolean
  isOpenDialog: boolean
  loading: boolean
  tab: number
  currentFolderPath: string
}

export default defineComponent({
  props: {
    parentId: {
      type: String,
      required: true,
    },
    meeting: {
      type: Meeting,
      required: true,
    },
  },
  components: {
    FileIcons,
    FileExplore,
    Icon,
    IconButton,
    I18nFormattedMessage,
  },
  setup(props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()
    const { getContext } = teamsContextContainer.useContainer()
    const { updateMeetingAsync } = meetingContainer.useContainer()
    const { getProjectAsync } = projectsContainer.useContainer()

    const generageCurrentFolderPath = async () => {
      const context = await getContext()
      return (
        context.rootFolder +
        "/定例会議アシスト-会議添付/" +
        getCleanPath(
          `${props.meeting.startTime.toDateJpStringFull()}_${
            props.meeting.subject
          }`.replaceAll(" ", "_")
        ) +
        "/"
      )
    }

    const state = reactive<State>({
      files: [],
      fileExplorePath: null,
      isLoadingCreate: false,
      isOpenDialog: false,
      loading: true,
      tab: 1,
      currentFolderPath: "",
    })

    watch(
      () => props.meeting.files,
      files => {
        state.files = files.slice() ?? []
      }
    )

    onMounted(() => {
      state.files = props.meeting.files.slice() ?? []
    })

    const onPathChange = (path: string | null) => {
      state.fileExplorePath = path
    }

    onMounted(async () => {
      const context = await getContext()
      state.fileExplorePath = context.rootFolder
    })

    const changeFiles = async (files: Array<ProjectFile>) => {
      state.isLoadingCreate = true
      const context = await getContext()
      const project = await getProjectAsync(context.entityId)
      const updateMeeting = new Meeting(props.meeting)
      updateMeeting.files = files
      await updateMeetingAsync(
        project.siteId,
        context.entityId,
        updateMeeting,
        props.parentId
      )
      emit("update", updateMeeting)
      state.isLoadingCreate = false
      state.isOpenDialog = false
    }

    const onClickWeb = (item: ProjectFile) => {
      window.open(item.webUrl)
    }

    const onClick = async (item: ProjectFile) => {
      state.fileExplorePath = item.parentPath
      state.currentFolderPath = await generageCurrentFolderPath()
      state.tab = 1
      state.isOpenDialog = true
    }

    const onLinkClear = async (item: ProjectFile) => {
      await changeFiles(state.files.filter(s => s.webUrl !== item.webUrl))
    }

    const openAttachment = async () => {
      const context = await getContext()
      state.currentFolderPath = await generageCurrentFolderPath()
      state.tab = 0
      state.isOpenDialog = true
      state.fileExplorePath = context.rootFolder
    }

    return {
      state,
      changeFiles,
      onPathChange,
      onClickWeb,
      onClick,
      onLinkClear,
      openAttachment,
      i18nFormattedMessage: formatMessage,
    }
  },
})
